import * as Sentry from "@sentry/browser";

export default function initSentry() {
    let releaseVersion = `trillertv-app@${process.env.VUE_APP_VERSION}`;
    if (process.env.NODE_ENV === 'development'){
        releaseVersion += '-dev';
    }

    Sentry.init({
        dsn: "https://a93eebca56f24d04894d55ee1df278f0@o1054473.ingest.us.sentry.io/6039907",

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        release: releaseVersion,
        integrations: [
            Sentry.browserTracingIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.03,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/https?:\/\/((www|stats|dev|ims-gw-dev-cf)\.)?trillertv\.com/],
    });
}
