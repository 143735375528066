import { setCookie, parseCookies } from "./utils/cookies";

export function initCJ (): void {
    const url = new URL(document.location.href);
    const params = url.searchParams ? url.searchParams : new URLSearchParams(url.search);

    if (params.has('cjevent')){
        setCookie('cjevent', params.get('cjevent') || '', 365);
    }
}

const hashCode = function(s: string) {
  return s.split("").reduce(function(a, b) {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
  }, 0);
}

function getCurrentPath(){
    const url = new URL(document.location.href);
    const params = new URLSearchParams()
    const allowed: string[] = ['page', 'categories'];
    allowed.forEach((row) => {
        if (url.searchParams.get(row)){
            params.set(row, url.searchParams.get(row) as string)
        }
    })
    let path = url.pathname;
    params.sort()
    const paramsStr = params.toString()

    if (paramsStr !== ''){
        path = `${path}?${paramsStr}`
    }

    return path
}
export function gaHelper(): void {
    const cookieName = 'fite-up';
    const path = getCurrentPath();
    const pathHash = hashCode(path);

    const cookies = parseCookies(document.cookie)
    let urls = []
    if (cookieName in cookies){
        urls = JSON.parse(cookies[cookieName])
    }
    if (!urls.includes(pathHash)){
        urls.push(pathHash);
        const date = new Date();
        date.setTime(date.getTime() + (30*60*1000));
        const expires = "; expires="+ date.toUTCString();

        document.cookie = cookieName + "=" + encodeURIComponent(JSON.stringify(urls)) + expires + "; SameSite=None; path=/; Secure";

        if (window.dataLayer){
            window.dataLayer.push({
                event: "unique_page_view",
                page_location: location.href,
                page_referrer: document.referrer,
            })
        }
    }
}