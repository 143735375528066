
type Cookies = {
    [key: string]: string
}

export function parseCookies(data: string): Cookies {
    return data
        .split(';')
        .map((v) => {
            const eq_idx = v.indexOf("=");
            const key = v.slice(0, eq_idx).trim()
            let value = v.slice(eq_idx + 1, v.length).trim()
            if ('"' === value[0]){
                value = value.slice(1, -1)
            }
            return [key, value]
        })
        .reduce((row: Cookies, value: string[]) => {
            if (!value[0] || value.length === 1){
                return row;
            }
            row[decodeURIComponent(value[0].trim())] = decodeURIComponent(value[1].trim());
            return row
        }, {});
}

export function setCookie(name: string, value:string, days: number): void {
    let expires = "";
    if (days) {
        const date: Date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + encodeURIComponent(value || "") + expires + "; path=/";
}

export function hasCookie(name: string, data: string): boolean{
    return name in parseCookies(data);
}
