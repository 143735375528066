
class Dropdown {
    constructor(targetElement = null, triggerElement = null) {
        this._targetEl = targetElement
        this._triggerEl = triggerElement
        this._visible = false
        this._init()
    }

    _init() {
        if (this._triggerEl) {
            this._triggerEl.addEventListener('click', (ev) => {
                ev.preventDefault();
                this.toggle()
            })
        }
    }

    _handleClickOutside(ev, targetEl) {
        const clickedEl = ev.target;
        if (clickedEl !== targetEl && !targetEl.contains(clickedEl) && !this._triggerEl.contains(clickedEl) && this._visible) {
            this.hide()
        }
        document.body.removeEventListener('click', this._handleClickOutside, true)
    }

    toggle() {
        if (this._visible) {
            this.hide()
            document.body.removeEventListener('click', this._handleClickOutside, true)
        } else {
            this.show()
        }
    }

    show() {
        if (!this._triggerEl.classList.contains('no-transform')){
            this._triggerEl.querySelectorAll('svg').forEach((el) => {el.style.transform = 'rotate(180deg)'})
            // this._triggerEl.querySelector('svg').style.transform = 'rotate(180deg)';
        }
        this._targetEl.classList.remove('hidden')
        document.body.addEventListener('click', (ev) => { this._handleClickOutside(ev, this._targetEl) }, true)
        // Update its position
        this._visible = true
    }

    hide() {
        this._triggerEl.querySelectorAll('svg').forEach((el) => {el.style.transform = null})
        // this._triggerEl.querySelector('svg').style.transform = null;
        this._targetEl.classList.add('hidden')
        this._visible = false
    }
}

function initDropdown() {
    document.querySelectorAll('[data-dropdown-toggle]').forEach(triggerEl => {
        const targetEl = document.getElementById(triggerEl.getAttribute('data-dropdown-toggle'))

        new Dropdown(targetEl, triggerEl)
    })
}

export {
    initDropdown
}

export default Dropdown
