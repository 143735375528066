import {initHeaderSearchApp} from "./search";
import { initDropdown } from "./shared/components/dropdown";
import { hasCookie } from "./lib/utils/cookies";
import { initCJ, gaHelper } from "./lib/tracking";
import { initApp as initPlaybackHistory } from "./playback-progress/app"
import 'lazysizes';
import initSentry from "./shared/sentry"
import {initValueProp, headerEffect, initNavData, initHeaderAccountBtn} from "./shared/utils";

initSentry();

// find another way for this, something like URLSearchParams
if (!String.prototype.includes) {
  String.prototype.includes = function(search, start) {
    'use strict';

    if (search instanceof RegExp) {
      throw TypeError('first argument must not be a RegExp');
    }
    if (start === undefined) { start = 0; }
    return this.indexOf(search, start) !== -1;
  };
}

const polyfills = []
if (!window.URLSearchParams){
    polyfills.push(import(/* webpackChunkName: "polyfill-url" */ 'core-js/modules/web.url-search-params.constructor'))
}

Promise.all(polyfills)
    .then(() => {
        initCJ();
        gaHelper();
    });

if (document.querySelector(".swiper-container") ||
    document.querySelector(".featured")){
    import(/* webpackChunkName: "trl-sl" */ './shared/carousels')
        .then((module) => {
            module.initFeatured();
            module.initCarousels(".swiper-container");
        })
}

// this block must be revisited
if (document.referrer !== '' && !document.referrer.includes('fite.tv')){
    const parser = document.createElement('a');
    parser.href = document.referrer;

    const date = new Date();
    date.setTime(date.getTime() + (28 * 24 * 60 * 60 * 1000));
    const expires = "; expires=" + date.toUTCString();
    document.cookie = "_rfd=" + encodeURIComponent(parser.hostname) + expires + "; path=/";
}

initNavData();
headerEffect();
initValueProp();
initDropdown();
initHeaderAccountBtn();
if (hasCookie("eid", document.cookie)){
    initPlaybackHistory();
}
window.addEventListener("load", () => {
    initHeaderSearchApp();
})

if (document.getElementById('js-player-page')){
    import(
        /* webpackChunkName: "trl-player" */
        /* webpackFetchPriority: "high" */
        /* webpackPreload: true */ './pages/player/index')
        .then((module) => {
            module.initApp();
        })
}

// if (document.getElementById('js-subs-page')){
//     import(
//         /* webpackChunkName: "trl-subs" */
//         /* webpackFetchPriority: "high" */
//         /* webpackPreload: true */ './pages/account/subscriptions')
//         .then((module) => {
//             module.initApp();
//         })
// }
