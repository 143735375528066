interface StorageObject {
    ttl: number;
    createdAt: number,
    data: object
}

const cache = function(key: string, value: object | null = null, ttl = 0): object | null {
    try {
        if (!value) {
            const data: string | null = localStorage.getItem(key);
            if (!data) {
                return null;
            }
            const record: StorageObject = JSON.parse(data);
            const now: number = new Date().getTime();
            if (Object.prototype.hasOwnProperty.call(record,"ttl") && (record["ttl"] === -1 || record["createdAt"] + record["ttl"] > now)) {
                return record["data"];
            }
        } else {
            if (ttl && ttl > 0){
                ttl = ttl * 60 * 1000
            }else if (ttl === 0 || !ttl){
                ttl = 60 * 60 * 1000
            }

            const data: StorageObject = {
                ttl: ttl,
                createdAt: new Date().getTime(),
                data: value,
            };
            localStorage.setItem(key, JSON.stringify(data));
        }
        return null;
    } catch (err) {
        return null;
    }
};

export { cache }