import {hasCookie} from "../lib/utils/cookies";
import {cache} from "../lib/utils/storage";

export function initValueProp(){
    const el = document.querySelector('#js-value-prop');

    if (el && !hasCookie('uid', document.cookie)){
        el.classList.remove('hidden');
        const mainEl = document.querySelector('main');
        if (mainEl) {
            mainEl.style.paddingTop = '28px';
        }

    }
}

export function headerEffect(){
    const navbar = document.querySelector('.page-header');

    if (navbar && navbar.firstElementChild){
        window.onscroll = function() {
            // pageYOffset or scrollY
            if (window.scrollY > 0) {
                navbar.classList.add('scrolled')
            } else {
                navbar.classList.remove('scrolled')
            }
        }
    }
}

function fixScheduleLink(el: HTMLAnchorElement){
    const categories = cache("fite.cf");
    if (!categories || (categories as string[]).length < 1){
        return
    }

    const url = new URL(el.href);
    url.searchParams.set('categories', (categories as string[]).join(','));
    el.href = url.href;
}

export function initNavData(){
    document.querySelectorAll<HTMLAnchorElement>('a[data-schedule]').forEach((el) => {
        fixScheduleLink(el);
    });
}

export const initHeaderAccountBtn = () => {
    const el = document.getElementById('js-nav-account');

    if (!el){
        return false;
    }

    if (hasCookie('eid', document.cookie)) {
        const btnContext = el.dataset;
        if (btnContext && btnContext.urlProfile){
            el.setAttribute('href', btnContext.urlProfile);
        }
        el.querySelector('svg use')?.setAttribute('xlink:href', '#user-profile');
    }else{
        el.setAttribute(
            'href',
            el.getAttribute('href') + `?return_to=${encodeURIComponent(window.location.href)}`
        );
    }
}
