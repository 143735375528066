import { cache } from "../lib/utils/storage";

export const SECONDS_A_MINUTE = 60
export const SECONDS_A_HOUR = SECONDS_A_MINUTE * 60
export const SECONDS_A_DAY = SECONDS_A_HOUR * 24
export const SECONDS_A_WEEK = SECONDS_A_DAY * 7
export const SECONDS_A_MONTH = SECONDS_A_DAY * 30
export const SECONDS_A_YEAR = SECONDS_A_DAY * 365

interface DurationValues {
    years?: number
    months?: number
    weeks?: number
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
}

export type Duration = {
    negative?: boolean
} & DurationValues

const units: Array<{ unit: keyof DurationValues; symbol: string, multiplier?: number }> = [
    { unit: 'years', symbol: 'Y', multiplier: SECONDS_A_YEAR },
    { unit: 'months', symbol: 'M', multiplier: SECONDS_A_MONTH },
    { unit: 'weeks', symbol: 'W', multiplier: SECONDS_A_WEEK },
    { unit: 'days', symbol: 'D', multiplier: SECONDS_A_DAY },
    { unit: 'hours', symbol: 'H', multiplier: SECONDS_A_HOUR },
    { unit: 'minutes', symbol: 'M', multiplier: SECONDS_A_MINUTE },
    { unit: 'seconds', symbol: 'S' },
]

// Construction of the duration regex
const r = (name: string, unit: string): string => `((?<${name}>-?\\d*[\\.,]?\\d+)${unit})?`
const durationRegex = new RegExp(
    [
        '(?<negative>-)?P',
        r('years', 'Y'),
        r('months', 'M'),
        r('weeks', 'W'),
        r('days', 'D'),
        '(T',
        r('hours', 'H'),
        r('minutes', 'M'),
        r('seconds', 'S'),
        ')?', // end optional time
    ].join(''),
)

function parseNum(s: string): number | undefined {
    if (s === '' || s === undefined || s === null) {
        return undefined
    }

    return parseFloat(s.replace(',', '.'))
}

export const InvalidDurationError = new Error('Invalid duration')

export function parse(durationStr: string): Duration {
    const match = durationRegex.exec(durationStr)
    if (!match || !match.groups) {
        throw InvalidDurationError
    }

    let empty = true
    const values: DurationValues = {}
    for (const { unit } of units) {
        if (match.groups[unit]) {
            empty = false
            values[unit] = parseNum(match.groups[unit])
        }
    }

    if (empty) {
        throw InvalidDurationError
    }

    const duration: Duration = values
    if (match.groups.negative) {
        duration.negative = true
    }

    return duration
}

export function durationToSeconds(durationStr: string): number {

    const duration: Duration = parse(durationStr);
    let seconds = 0;

    for (const { unit, multiplier } of units) {
        if (duration[unit]) {
            if (multiplier){
                seconds = seconds + (duration[unit] as number * multiplier)
            }else{
                seconds = seconds + (duration[unit] as number);
            }

        }
    }

    return seconds;
}

interface VideoPlayback {
    video_id: number;
    created_at: string;
    start_position: string;
}

interface VPlaybackEntry {
    video_id: number;
    created_at: string;
    position: number;
}

const displayProgression = (data: Array<VPlaybackEntry>) => {
    const els = document.querySelectorAll('.progress-overlay');
    if (!els){
        return
    }

    for (const el of els){
        const barEl: HTMLElement | null = el.querySelector(".progress-bar");
        if (el.parentElement && el.parentElement.dataset.vvid && barEl){
            const videoId = parseInt(el.parentElement.dataset.vvid);
            const duration = parseInt(el.parentElement.dataset.duration || '');
            const airTime = el.parentElement.dataset.datetime || '';
            if (videoId < 1 || duration < 1)
                return;

            const record = data.filter((row) => {
                const createdAt = new Date(row.created_at);
                return row.video_id === videoId && row.position > 0 && (!airTime || new Date(airTime) < createdAt)
            }).shift()
            if (record){
                el.classList.remove("hidden");
                el.classList.add('block');
                const percentage = Math.round((record.position/duration)*100);
                barEl.style.width = percentage + "%";
            }
        }

    }

}

export const initApp = () => {
    const el = document.querySelector("body");
    if (!el || !el.dataset.cw || el.dataset.cw !== "true"){
        return;
    }
    const data = cache("fite.pbp");

    if (data){
        displayProgression(data as Array<VPlaybackEntry>)
    }else{
        fetch(
            "/api/_v2/users/me/playbacks/", {
                credentials: "include"
            })
            .then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json()
                }else{
                    throw new Error(response.statusText);
                }
            })
            .then((data) => {
                const playbacks: Array<VPlaybackEntry> = [];

                data.forEach((row: VideoPlayback) => {
                    playbacks.push({
                        video_id: row.video_id,
                        created_at: row.created_at,
                        position: durationToSeconds(row.start_position),
                    })
                });

                cache("fite.pbp", playbacks, 60);
                return playbacks
            })
            .then(displayProgression)
            .catch(() => {return null})
    }
}

export default initApp;